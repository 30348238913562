import { StatusPanel } from 'Shared/status_panel';
import { Modal } from 'Shared/modal';
import { Utils } from 'Shared/utils';
import { Auth } from 'Shared/auth';
import { StatusNotice } from 'Shared/status_notice';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('TeamProfileFrameModule', function () {
   TeamProfileBox.initialize();

   $$('.repairServicesShowMore').addEvent('click', function (ev) {
      ev.stop();
      $('repairServices').toggle();
   });

   if (App.showStatus) {
      let notice = new StatusNotice();
      $('inviteStatus').adopt(notice.element).inject($('bodyTop'), 'top');

      if (App.success) {
         notice.good(_js('You have been added to this team!'));
      } else {
         notice.error(_js('There was an error adding you to this team'));
      }
   }

   if (App.promptLogin) {
      let emailField = '';
      if (App.hasUser) {
         Auth.login({
            from: 'navLogin',
            message: _js('Logging in will reload the page.'),
            reload: !Utils.hasUnsavedChanges(),
         });
         emailField = 'Ecom_BillTo_Online_Email_Login';
      } else {
         Auth.login({
            from: 'navSignup',
            message: _js('Registering will reload the page.'),
            reload: true,
            register: true,
         });
         emailField = 'Ecom_BillTo_Online_Email_Login_Reg';
      }
   }

   if ($('inviteContainer')) {
      new InviteForm();
   }
});

// eslint-disable-next-line no-var
export var InviteForm = (window.InviteForm = new Class({
   Implements: Options,

   options: {
      popFx: {
         duration: 200,
         transition: 'quad:out',
      },
   },

   initialize: function (options) {
      this.setOptions(options);

      this.inviteContainer = $('inviteContainer');
      this.modalLink = $('modalLink');

      // Grab relevant DOM elements.
      this.container = $('inviteContainer');
      this.userList = $('userListContainer');
      this.forceInvites = $('forceInvites');
      this.emails = $('inviteEmails');
      this.message = $('inviteMessage');
      this.submitButton = $('inviteSubmit');
      this.closeButton = $('inviteClose');
      this.emailsHelp = $('inviteEmailsHelp');
      this.invalidEmails = $('inviteInvalidEmails');
      this.noticeDiv = $('statusNoticeDiv');

      this.modalLink.addEvent(
         'click',
         function (ev) {
            ev.stop();

            // Set up examples for the invite emails.
            this.emails.addEvent(
               'focus',
               function (ev) {
                  this.emailsHelp.show();
                  this.invalidEmails.hide();
               }.bind(this)
            );

            this.setupNotice('title');

            Modal.open({
               type: 'element',
               element: this.inviteContainer,
            });

            this.inviteContainer.show();
         }.bind(this)
      );

      // When we click on the "send invites" button...
      this.submitButton.addEvent(
         'click',
         function (ev) {
            // Pull out the valid email addresses.
            let validEmails = this.matchEmails();
            if (validEmails.length <= 0) {
               return;
            }

            // Grab the personal message and account type.
            let teamid = this.inviteContainer.get('data-teamid');
            let message = this.message.get('value');
            let force = this.forceInvites ? this.forceInvites.get('checked') : false;

            this.processInvites(teamid, validEmails, message, force);
         }.bind(this)
      );

      // Close the form when we click the nevermind button.
      this.closeButton.addEvent(
         'click',
         function (ev) {
            //this.resetForm(); Do we want to reset data on close?
            Modal.close();
         }.bind(this)
      );
   },

   matchEmails: function () {
      // Pull out the valid email addresses.
      let list = this.emails.get('value');
      let matches = list.match(/([a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,4})/gi);
      if (!matches) {
         this.invalidEmailsError();
         return [];
      }

      // Remove duplicates.
      let validEmails = [];
      for (let i = 0; i < matches.length; i++) {
         if (validEmails.contains(matches[i])) {
            continue;
         }
         validEmails.push(matches[i]);
      }

      return validEmails;
   },

   invalidEmailsError: function () {
      this.emailsHelp.hide();
      this.invalidEmails.show();
      this.emails.setStyle('border-color', '#8a1c1c');
      this.emails.setStyle('background', '#efd0d0');
   },

   setupNotice: function (type, message) {
      let notice = new StatusNotice();
      this.noticeDiv.empty();
      this.noticeDiv.adopt(notice.element);
      switch (type) {
         case 'error':
            notice.error(_js('Remaining emails are either invalid or already in team.'));
            break;
         case 'serious_error':
            notice.error(_js('Operation Failed') + (message ? ': ' + message : ''));
            break;
         case 'send':
            notice.notice(_js('Sending Invites...'));
            break;
         case 'title':
         default:
            notice.good(App.title);
            break;
      }
   },

   processInvites: function (teamid, emails, message, force) {
      if (emails.length > App.teamInvite.maxInvitesPerFormSubmission) {
         this.setupNotice('serious_error', _js(App.teamInvite.error.maxInvitesExceeded));
         return;
      }

      // Prevent further changes.
      this.lockForm();
      this.setupNotice('send');

      new Request.AjaxIO('sendInvites', {
         onSuccess: function (response) {
            if (response) {
               this.setupNotice('error');
               this.unlockForm();
               this.emails.set('value', response);
            } else {
               Modal.close();
               this.resetForm();
               StatusPanel.notify(_js('Invites sent!'), 2, {
                  reload: false,
               });
            }
         }.bind(this),

         onError: function (response) {
            this.setupNotice('serious_error', response.error);
            this.unlockForm();
         }.bind(this),
      }).send(teamid, emails, message, force);
   },

   resetForm: function () {
      this.unlockForm();
      this.emailsHelp.show();
      this.invalidEmails.hide();

      this.message.set('value', '');
   },

   lockForm: function () {
      // Disable the controls.
      this.emails.disabled = true;
      this.message.disabled = true;
      if (this.forceInvites !== null) {
         this.forceInvites.disabled = true;
      }
      this.submitButton.disabled = true;
      this.submitButton.addClass('buttonLinkDisabled');
      this.closeButton.disabled = true;
      this.closeButton.addClass('buttonLinkDisabled');
   },

   unlockForm: function () {
      // Enable the controls.
      this.emails.disabled = false;
      this.message.disabled = false;
      if (this.forceInvites !== null) {
         this.forceInvites.disabled = false;
      }
      this.submitButton.disabled = false;
      this.submitButton.removeClass('buttonLinkDisabled');
      this.closeButton.disabled = false;
      this.closeButton.removeClass('buttonLinkDisabled');
   },
}));

// eslint-disable-next-line no-var
export var TeamProfileBox = (window.TeamProfileBox = {
   initialize: function () {
      when($('reqLogin'), this.requireLogin);

      this.leaveTeam();

      this.joinTeam();
   },

   requireLogin: function (el) {
      Auth.login({
         from: 'navLogin',
         message: _js('Logging in will reload the page.'),
         reload: true,
      });
   },

   leaveTeam: function () {
      $$('.js-leave-team-button').addEvent('click', function (ev) {
         ev.stop();
         let memberCount = this.get('data-memberCount');
         let result;
         if (!App.manageTeamUsers && memberCount === 1) {
            result = confirm(
               _js(
                  'Warning! You are the last member of this team. Leaving this team will delete the team. Do you want to continue?'
               )
            );
         } else {
            result = confirm(
               _js(
                  "Warning! Leaving this team will remove all of your contributions and activities from your team's history. Do you want to continue?"
               )
            );
         }

         if (result) {
            new Request.API_2_0(App.teamApi.leaveUrl + App.userid, {
               method: App.teamApi.leaveMethod,
               statusPanelMessage: _js('Leaving...'),
               onSuccess: function (response) {
                  // Strip off `joinCode` URL param if present and refresh.
                  window.location.search = '';
               },
               onFailure: function (xhr, message) {
                  alert(message);
               },
            }).send();
         }
      });
   },

   joinTeam: function () {
      $$('.js-join-team-button').addEvent('click', function (ev) {
         ev.stop();

         let self = this;

         Auth.required({
            reload: false,
            message: _js('Log in to join this team.'),
            onAuthorize: function (response) {
               if (response && response.userid) {
                  // eslint-disable-next-line no-var
                  var userid = response.userid;
               } else {
                  userid = App.userid;
               }

               let joinCode = self.get('data-joinCode');
               let codeParam = joinCode ? '?join_code=' + joinCode : '';

               let joinUrl = App.teamApi.joinUrl + userid + codeParam;

               new Request.API_2_0(joinUrl, {
                  method: App.teamApi.joinMethod,
                  statusPanelMessage: _js('Joining...'),
                  onSuccess: function (response) {
                     window.location.reload();
                  },
                  onFailure: function (xhr, message) {
                     alert(message);
                  },
               }).send();
            },
         });
      });
   },
});
